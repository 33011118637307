/* eslint-disable angular/document-service,no-negated-condition */
import helpers from '../helpers/helpers';
import errorLoginTemplate from './error-login.html';
import { EventBus } from '../event-bus/event-bus';
import {
  AUTHORIZED_USER_ROLES,
  ERROR_MESSAGES, SERVER_ERROR_CODE,
  USER_ID_REGEXP
} from '../login.constants';

class LoginFormController {
  constructor() {
    this.elements = {};
    this.requiredElementIds = [
      'USER',
      'PASSWORD',
      'submitLogin',
      'forgotPassword',
      'rememberUsername',
      'loginInfo',
      'errorMessage',
      'showHidePasswordLabel',
      'showHidePassword'
    ];
  }

  init() {
    this.helpers = helpers;
    this.elements = this.helpers.findElements(this.requiredElementIds);
    // TODO: remove this line when dplogin is updated and attr disabled removed;
    this.elements.submitLogin.disabled = false;
    this.attachEvents();
    this.setDefaultValues();
    this.toggleShowHidePassword();
    this.updateFooterDate();

    this.helpers.addTouchEventToButton(this.elements.submitLogin);
  }

  attachEvents() {
    this.elements.USER.addEventListener('keyup', this.toggleValidationErrors.bind(this), false);
    this.elements.PASSWORD.addEventListener('keyup', this.toggleValidationErrors.bind(this), false);
    this.elements.loginInfo.addEventListener('submit', this.saveUsername.bind(this), false);

    if (navigator.userAgent.match(/Trident.*rv:11\./)) {
      this.elements.USER.addEventListener('focus', this.handleIEevents.bind(this), false);
      this.elements.PASSWORD.addEventListener('focus', this.handleIEevents.bind(this), false);
      this.elements.USER.addEventListener('input', this.handleIEevents.bind(this), false);
      this.elements.PASSWORD.addEventListener('input', this.handleIEevents.bind(this), false);
    } else {
      this.elements.USER.addEventListener('input', this.toggleValidationErrors.bind(this), false);
      this.elements.PASSWORD.addEventListener('input', this.toggleValidationErrors.bind(this), false);
    }

    this.elements.showHidePassword.addEventListener('change', this.onShowHide.bind(this), false);
  }

  updateFooterDate() {
    const year = (new Date()).getFullYear();
    const footerElement = document.getElementsByClassName('site-footer__copyright')[0];

    footerElement.innerHTML = `&copy; ${ year } Penske. All Rights Reserved.`;
  }

  setDefaultValues() {
    const rememberUsername = localStorage.getItem('rememberUsername');

    if (rememberUsername === 'true') {
      this.elements.USER.value = localStorage.getItem('userId');
      this.elements.rememberUsername.checked = true;
    }
  }

  displayError() {
    const allcookies = document.cookie;
    const retry = allcookies.indexOf('SMTRYNO=');

    if (retry !== -1) {
      this.elements.errorMessage.style.display = 'block';
      this.elements.USER.value = localStorage.getItem('userId');
      this.clearUserData();
      EventBus.publish('modal:open');

      document.cookie = 'SMTRYNO=; Path=/; Domain=.fleetinsight.com; Expires=Thu, 01 Jan 1970 00:00:00 GMT;';
    }
  }

  handleIEevents(event) {
    if (this.previousEvent && this.previousEvent !== 'focus' && event.type === 'input') {
      this.toggleValidationErrors(event);
    }
    this.previousEvent = event.type;
  }

  toggleValidationErrors(event) {
    const inputControl = event.target;

    if (event.type === 'keyup' && event.keyCode === 9) {
      return;
    }

    if (inputControl.value) {
      inputControl.classList.remove('login-form__input-error');
    } else {
      inputControl.classList.add('login-form__input-error');
    }

    this.removeErrorMessage();
    this.toggleShowHidePassword();
  }

  removeErrorMessage() {
    this.elements.errorMessage.style.display = 'none';
  }

  saveUsername(event) {
    const target = event.target;

    event.preventDefault();

    if (this.validateForm()) {
      localStorage.setItem('userId', this.elements.USER.value);
      localStorage.setItem('rememberUsername', this.elements.rememberUsername.checked);

      this.sendCheckRoleRequest(target);
    }
  }

  validateForm() {
    const isPasswordInvalid = !this.elements.PASSWORD.value && 'PASSWORD';
    const isUserNameInvalid = !this.elements.USER.value && 'USER';
    const invalidField = isUserNameInvalid || isPasswordInvalid;

    if (invalidField) {
      this.elements[invalidField].classList.add('login-form__input-error');
      this.helpers.scrollToErrorField(this.elements[invalidField]);
    }

    if (!USER_ID_REGEXP.test(this.elements.USER.value)) {
      this.displayErrorMessage('EX0001');

      return false;
    }

    return !invalidField;
  }

  isPasswordAutofilled() {
    let webkitPassword;

    try {
      webkitPassword = document.querySelector('#PASSWORD:-webkit-autofill');
    } catch (e) {
      webkitPassword = false;
    }

    return !!(this.elements.USER.value && webkitPassword);
  }

  toggleShowHidePassword() {
    this.elements.PASSWORD.classList.remove('removed');

    this.elements.showHidePasswordLabel.style.display =
      (this.elements.PASSWORD.value || this.isPasswordAutofilled()) ? 'block' : 'none';
  }

  onShowHide() {
    this.elements.PASSWORD.type = this.elements.showHidePassword.checked ? 'text' : 'password';
  }

  sendCheckRoleRequest(target) {
    const BASE_URL = process.env.LOGIN_ENDPOINT || 'https://apidev.penske.com/penske/driverapi/v1/';
    const url = `${ BASE_URL }forgot/password/profileandrole/${ encodeURIComponent(this.elements.USER.value.trim()) }/`;

    this.helpers.sendRequest('GET', url, {}, this.checkRole.bind(this, target), this.onErrorHandler.bind(this));
  }

  checkRole(target, data) {
    const { role } = data;

    if (AUTHORIZED_USER_ROLES.indexOf(role) === -1) {
      this.clearUserData();
      this.displayPermissionError();
    } else {
      this.helpers.displayPageSpinner();
      this.removeErrorNotification();
      target.submit();
    }
  }

  removeErrorNotification() {
    const errorNotification = document.getElementById('permissionError');

    if (errorNotification) {
      this.elements.loginInfo.removeChild(errorNotification);
    }
  }

  displayPermissionError() {
    const errorNotification = document.getElementById('permissionError');

    if (!errorNotification) {
      this.elements.loginInfo.insertAdjacentHTML('afterbegin', errorLoginTemplate);
    }

    this.helpers.scrollToErrorField(document.getElementById('permissionError'));
    this.clearPasswordField();
  }

  clearPasswordField() {
    this.elements.PASSWORD.value = '';
    this.elements.showHidePasswordLabel.style.display = 'none';
    this.elements.PASSWORD.classList.add('removed');
  }

  onErrorHandler(err) {
    const { errorCode } = err;

    this.clearErrors();
    this.clearUserData();

    if (ERROR_MESSAGES[errorCode]) {
      this.displayErrorMessage(errorCode);
    } else {
      this.displayPermissionError();
    }
  }

  displayErrorMessage(errorCode = SERVER_ERROR_CODE) {
    this.elements.errorMessage.innerHTML = ERROR_MESSAGES[errorCode];
    this.elements.errorMessage.style.display = 'block';
    this.helpers.scrollToErrorField(this.elements.errorMessage);
    this.clearPasswordField();
  }

  clearUserData() {
    this.elements.rememberUsername.checked = false;
    localStorage.removeItem('userId');
    localStorage.removeItem('rememberUsername');
  }

  clearErrors() {
    this.removeErrorMessage();
    this.removeErrorNotification();
  }
}

export default LoginFormController;

