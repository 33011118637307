/* eslint-disable angular/document-service */
import privacyTemplate from './privacy-modal.html';
import termsOfUseTemplate from './terms-of-use-modal.html';

const MODAL_TEMPLATES = {
  privacyButton: privacyTemplate,
  termsButton: termsOfUseTemplate
};

class ModalController {
  constructor() {
    this.body = document.body;
    this.privacyButton = document.getElementById('privacyButton');
    this.termsButton = document.getElementById('termsButton');

    this.bindEvents();
  }

  bindEvents() {
    this.privacyButton.addEventListener('click', this.openModal.bind(this), false);
    this.termsButton.addEventListener('click', this.openModal.bind(this), false);
  }

  openModal(event) {
    this.body.classList.add('overlay-open');
    this.body.addEventListener('touchmove', ModalController.listener, false);

    this.body.insertAdjacentHTML('beforeend', MODAL_TEMPLATES[event.target.id]);

    document.getElementById('modal').addEventListener('click', this.dismiss.bind(this), false);
    document.getElementById('closeModal').addEventListener('click', this.closeModal.bind(this), false);
  }

  dismiss(event) {
    const targetEl = event.target;

    if (targetEl && !(targetEl.className === 'modal modal__visability' || targetEl.className === 'modal__wrap')) {
      return;
    }

    this.closeModal();
  }

  closeModal() {
    let el = document.getElementById('modal');

    el.parentElement.removeChild(el);
    this.body.classList.remove('overlay-open');
    this.body.removeEventListener('touchmove', this.listener, false);

    el = null;
  }

  static listener(e) {
    if (e.target === e.currentTarget || e.target.className === 'modal__holder' || e.target.className === 'modal__title') {
      e.preventDefault();
      e.stopPropagation();
    }
  }
}

export default ModalController;
