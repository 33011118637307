/* eslint-disable angular/document-service,no-negated-condition */
import helpers from '../helpers/helpers';
import { EventBus } from '../event-bus/event-bus';
import { handleTabKeydown, KEY_ESC } from './login-modal.helpers';

const MODAL_WRAPPER_CLASS = 'login-form__modal-wrapper--visible';

class LoginModalController {
  constructor() {
    this.requiredElementIds = [
      'loginModal',
      'loginInfo',
      'modalContainer',
      'closeModalIcon',
      'toggleLoginButton',
      'USER'
    ];
  }

  init() {
    this.elements = helpers.findElements(this.requiredElementIds);
    this.documentBody = document.body;
    this.attachEvents();
  }

  attachEvents() {
    this.elements.loginModal.addEventListener('click', this.onClickOutside.bind(this), false);
    this.elements.loginModal.addEventListener('keydown', this.handleModalKeydown.bind(this), false);
    this.elements.closeModalIcon.addEventListener('click', this.dismissModal.bind(this), false);
    this.elements.closeModalIcon.addEventListener('keydown', this.handleCloseKeydown.bind(this), false);

    EventBus.subscribe('modal:open', this.showLoginForm.bind(this));
    EventBus.subscribe('server:error', this.dismissModal.bind(this));
    EventBus.subscribe('modal:close', this.dismissModal.bind(this));
  }

  showLoginForm() {
    helpers.scrollToTop();

    this.elements.loginModal.classList.add(MODAL_WRAPPER_CLASS);
    this.documentBody.classList.add('modal-open');

    this.elements.USER.focus();
  }

  hideLoginForm() {
    this.elements.loginModal.classList.remove(MODAL_WRAPPER_CLASS);
    this.documentBody.classList.remove('modal-open');
    this.elements.toggleLoginButton.focus();
  }

  onClickOutside(event) {
    const targetEl = event.target;
    const forgotDialogEl = document.getElementById('forgotDialog');

    if (this.elements.loginModal === targetEl ||
        this.elements.modalContainer === targetEl ||
        forgotDialogEl === targetEl) {
      this.dismissModal();
    }
  }

  handleModalKeydown(event) {
    if (this.isForgotDialogOpened()) {
      handleTabKeydown(event, this.elements.closeModalIcon, document.getElementById('forgotDialogSubmit'));
    } else {
      handleTabKeydown(event, this.elements.USER, this.elements.closeModalIcon);
    }

    if (event.keyCode === KEY_ESC) {
      this.dismissModal();

      event.preventDefault();
    }
  }

  handleCloseKeydown(event) {
    const keyCode = (event.keyCode ? event.keyCode : event.which);

    if (keyCode === 13) {
      this.dismissModal();

      event.preventDefault();
    }
  }

  isForgotDialogOpened() {
    return document.getElementById('forgotDialog');
  }

  dismissModal() {
    this.hideLoginForm();

    if (this.isForgotDialogOpened()) {
      EventBus.publish('modal:wasClosed');
    }
  }
}

export default LoginModalController;
