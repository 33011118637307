/* eslint-disable angular/window-service,angular/document-service,angular/json-functions,angular/timeout-service */
import { EventBus } from '../event-bus/event-bus';
import alertTemplate from './alert-notification.html';
import pageSpinner from './page-spinner.html';
import serverErrorPage from '../server-error-page/server-error.html';
import {
  SERVER_ERROR_CODE,
  EMAIL_REGEXP
} from '../login.constants';

const CLIENT_ID = process.env.CLIENT_ID;

const helpers = {
  findElements(keys) {
    const out = {};

    (keys || []).forEach((id) => {
      out[id] = document.getElementById(id);
    });

    return out;
  },

  clearLinks(keys, obj) {
    (keys || []).forEach((key) => {
      obj[key] = null;
    });
  },

  isEmailValid(target) {
    const email = target.value;

    return !email || EMAIL_REGEXP.test(email);
  },

  sendRequest(method, url, body = {}, successCallback, errorCallback) {
    const request = new XMLHttpRequest();
    const hideSpinner = this.hidePageSpinner.bind(this);
    const checkServerError = this.checkServerError.bind(this);
    const checkAccessError = this.checkAccessError.bind(this);
    const checkSuccessResponse = this.checkSuccessResponse.bind(this);

    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        hideSpinner();

        checkServerError(request);
        checkAccessError(request, errorCallback);
        checkSuccessResponse(request, errorCallback, successCallback);
      }
    };

    this.displayPageSpinner();
    request.open(method, url, true);
    request.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
    request.setRequestHeader('X-IBM-Client-Id', CLIENT_ID);
    request.send(JSON.stringify(body));
  },

  checkServerError(request) {
    if (request.status === 500 || request.status === 0) {
      this.displayServerErrorPage();
    }
  },

  checkAccessError(request, errorCallback) {
    if ([ 400, 401, 404 ].indexOf(request.status) !== -1) {
      errorCallback({
        errorCode: SERVER_ERROR_CODE
      });
    }
  },

  checkSuccessResponse(request, errorCallback, successCallback) {
    if (request.status === 200 && request.responseText) {
      const data = JSON.parse(request.responseText);

      if (data.success === false) {
        errorCallback(data.errorData);
      } else {
        successCallback(data.data);
      }
    }
  },

  displayAlert() {
    if (!this.alert) {
      document.body.insertAdjacentHTML('beforeend', alertTemplate);
      this.alert = document.getElementById('alertNotification');
      this.hideAlertButton = document.getElementById('hideAlertButton');
      this.hideAlertButton.addEventListener('click', this.hideAlertMessage.bind(this));
    }

    window.scrollTo(0, 0);
    this.alert.classList.add('pn-alert--active');

    this.timeOutId = setTimeout(() => {
      this.hideAlertMessage();
    }, 5000);
  },

  hideAlertMessage() {
    clearTimeout(this.timeOutId);
    this.alert.classList.remove('pn-alert--active');
  },

  validateUserId(input, event) {
    if (this.isIgnorableKey(event.keyCode)) { return; }

    const value = input.value.replace(/[^\w\d#$%'._@-]/gi, '');

    if (input.value !== value) { input.value = value; }
  },

  validatePassword(input, event) {
    if (this.isIgnorableKey(event.keyCode)) { return; }

    const value = input.value.replace(/[^\w\d._@-]/gi, '');
    let message = '';

    if (value.length >= 8 && value.length <= 15) {
      if (!/^[a-z](?:_?[a-z0-9]+)*[a-z]$/i.test(value)) {
        message = 'Password must start and end with a letter';
      } else if (!/^(?=(.*\d){2})/.test(value)) {
        message = 'Password must contain 2 numbers';
      } else if (!/^(?=(.*[A-Z]))/.test(value)) {
        message = 'Password must contain one upper case letter';
      } else if (!/^(?=(.*[a-z]))/.test(value)) {
        message = 'Password must contain one lower case letter';
      }
    } else if (value.length) {
      message = 'Password should be 8-15 characters long';
    }

    input.setCustomValidity(message);

    if (input.value !== value) { input.value = value; }
  },

  isIgnorableKey(key) {
    return this.IGNORABLE_KEY_CODES.indexOf(key) !== -1;
  },

  displayPageSpinner() {
    if (!this.spinner) {
      document.body.insertAdjacentHTML('beforeend', pageSpinner);
      this.spinner = document.getElementById('pageSpinner');
    }

    this.spinner.style.display = 'block';
  },

  hidePageSpinner() {
    this.spinner.style.display = 'none';
  },

  displayServerErrorPage() {
    if (!this.serverError) {
      document.getElementsByClassName('content')[0].insertAdjacentHTML('beforeend', serverErrorPage);
      document.getElementsByClassName('login-page')[0].style.display = 'none';

      EventBus.publish('server:error');
    }
  },

  scrollToErrorField(input) {
    const elementPageYOffset = input.getBoundingClientRect().top;
    const headerOffsetY = 90;

    window.scrollTo(0, window.pageYOffset + elementPageYOffset - headerOffsetY);

    input.focus();
  },

  addTouchEventToButton(element) {
    element.addEventListener('touchstart', () => {
      element.classList.remove('mobile-touch');
    }, false);

    element.addEventListener('touchend', () => {
      element.classList.add('mobile-touch');
    }, false);
  },

  scrollToTop() {
    window.scrollTo(0, 0);
  },

  IGNORABLE_KEY_CODES: [ 37, 38, 39, 40 ]
};

export default helpers;
