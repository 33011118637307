import helpers from '../helpers/helpers';
import { EventBus } from '../event-bus/event-bus';

class MainController {
  constructor() {
    this.requiredElementIds = [
      'toggleLoginButton'
    ];
  }

  init() {
    this.elements = helpers.findElements(this.requiredElementIds);
    this.attachEvents();
  }

  attachEvents() {
    this.elements.toggleLoginButton.addEventListener('click', this.showLoginForm.bind(this), false);
  }

  showLoginForm() {
    EventBus.publish('modal:open');
  }
}

export default MainController;
