export const KEY_ESC = 27;
export const KEY_TAB = 9;

export const handleBackwardTab = (event, firstFocusableEl, lastFocusableEl) => {
  if (document.activeElement === firstFocusableEl) {
    event.preventDefault();
    lastFocusableEl.focus();
  }
};
export const handleForwardTab = (event, firstFocusableEl, lastFocusableEl) => {
  if (document.activeElement === lastFocusableEl) {
    event.preventDefault();
    firstFocusableEl.focus();
  }
};

export const handleTabKeydown = (event, firstFocusableEl, lastFocusableEl) => {
  if (event.keyCode === KEY_TAB) {
    if (event.shiftKey) {
      handleBackwardTab(event, firstFocusableEl, lastFocusableEl);
    } else {
      handleForwardTab(event, firstFocusableEl, lastFocusableEl);
    }
  }
};
