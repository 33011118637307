/* eslint-disable angular/document-service */

import forgotDialogTemplate from './forgot-dialog.html';
import helpers from '../helpers/helpers';
import { EventBus } from '../event-bus/event-bus';

const ERROR_MESSAGES = {
  DR0005: 'Invalid User ID.',
  DR0027: 'Email Address could not be authenticated.',
  DR0060: 'User ID could not be authenticated.',
  DR0065: 'User ID is no longer active. Please call <a class="mobile-tel mobile-tel__error" href="tel:(844) 426-4555">(844) 426-4555</a><span class="desktop-tel"><span>(844)</span> <span>426-4555</span></span> for further assistance.',
  DR0069: 'User ID is no longer active. Please call <a class="mobile-tel mobile-tel__error" href="tel:(844) 426-4555">(844) 426-4555</a><span class="desktop-tel"><span>(844)</span> <span>426-4555</span></span> for further assistance.',
  DRIVER: 'Forgot Password process must be completed from the Penske Driver App.'
};

const ELEMENT_IDS = [
  'userId',
  'password',
  'emailWrap',
  'emailInput',
  'userIdWrap',
  'userIdInput',
  'forgotDialogSubmit',
  'userIdError',
  'emailError',
  'confirmationDialog',
  'forgotDialogForm',
  'forgotDialog',
  'confirmationButton',
  'forgotDialogBack',
  'loginInfo'
];

const BASE_URL = process.env.LOGIN_ENDPOINT || 'https://apidev.penske.com/penske/driverapi/v1/';
const FLEET_URL = process.env.FLEET_URL;

class ForgotDialogController {
  constructor() {
    this.helpers = helpers;
    this.elements = {};

    this.dialogContainer = document.getElementsByClassName('container')[0];
    this.bindForgotPasswordEvent();
  }

  bindForgotPasswordEvent() {
    this.forgotPassword = document.getElementById('forgotPassword');
    this.forgotPassword.addEventListener('click', this.displayForgotDialog.bind(this), false);
  }

  displayForgotDialog() {
    this.helpers.scrollToTop();
    this.dialogContainer.insertAdjacentHTML('beforeend', forgotDialogTemplate);
    this.initUiElements();
    this.bindEvents();
    this.toggleOptionsVisability();

    this.elements.loginInfo.style.display = 'none';
    this.elements.forgotDialog.classList.remove('hidden');

    this.elements.userId.focus();
  }

  initUiElements() {
    this.elements = this.helpers.findElements(ELEMENT_IDS);
  }

  bindEvents() {
    this.elements.userId.addEventListener('change', this.toggleOptionsVisability.bind(this), false);
    this.elements.password.addEventListener('change', this.toggleOptionsVisability.bind(this), false);
    this.elements.forgotDialogSubmit.addEventListener('click', this.onSubmitHandler.bind(this), false);
    this.elements.forgotDialogSubmit.addEventListener('touchstart', this.onTouchStart.bind(this));
    this.elements.forgotDialogSubmit.addEventListener('touchend', this.onTouchEnd.bind(this));
    this.elements.confirmationButton.addEventListener('click', this.triggerCloseModal.bind(this), false);
    this.elements.forgotDialogBack.addEventListener('click', this.backToLogin.bind(this), false);
    this.elements.emailInput.addEventListener('keyup', this.onKeyUpEmailHandler.bind(this), false);
    this.elements.emailInput.addEventListener('focusout', this.validateEmail.bind(this), false);
    this.elements.userIdInput.addEventListener('keyup', this.onKeyUpUserIdHandler.bind(this), false);
    this.elements.userIdInput.addEventListener('focusout', this.validateUserId.bind(this), false);

    if (!navigator.userAgent.match(/Trident.*rv:11\./)) {
      this.elements.emailInput.addEventListener('input', this.onKeyUpEmailHandler.bind(this), false);
      this.elements.userIdInput.addEventListener('input', this.onKeyUpUserIdHandler.bind(this), false);
    }

    this.modalWasCloseUnsubscribe = EventBus.subscribe('modal:wasClosed', this.backToLogin.bind(this));
  }

  toggleOptionsVisability() {
    const optionToShow = this.elements.password.checked ? this.elements.userIdWrap : this.elements.emailWrap;
    const optionToHide = this.elements.userId.checked ? this.elements.userIdWrap : this.elements.emailWrap;

    document.getElementsByClassName('forgot-dialog__radio-list')[0].classList.add('active');

    optionToShow.classList.add('visible');
    optionToHide.classList.remove('visible');
  }

  onSubmitHandler() {
    this.selectDefaultOption();

    if (this.isFormInvalid() || this.requestInProgress) {
      return false;
    }

    this.changeSubmitButtonState('Submitting', 'add');
    this.requestInProgress = true;

    if (this.elements.userId.checked) {
      this.sendForgotUserIdRequest();
    } else {
      this.sendCheckRoleRequest();
    }
  }

  selectDefaultOption() {
    if (!(this.elements.userId.checked || this.elements.password.checked)) {
      this.elements.userId.checked = true;
      this.toggleOptionsVisability();
    }
  }

  isFormInvalid() {
    const isEmailInValid = this.elements.userId.checked && !this.validateEmail() && 'emailInput';
    const isUserIdInValid = this.elements.password.checked && !this.validateUserId() && 'userIdInput';
    const invalidInput = isEmailInValid || isUserIdInValid;

    if (invalidInput) {
      this.helpers.scrollToErrorField(this.elements[invalidInput]);
    }

    return invalidInput;
  }

  sendForgotUserIdRequest() {
    const url = `${ BASE_URL }forgot/username`;
    const data = {
      email: this.elements.emailInput.value.trim()
    };

    this.helpers.sendRequest('POST', url, data, this.displaySuccessConfirmation.bind(this), this.displayServerError.bind(this, 'emailError', 'emailInput'));
  }

  sendCheckRoleRequest() {
    const url = `${ BASE_URL }forgot/password/profileandrole/${ encodeURIComponent(this.elements.userIdInput.value.trim()) }/`;

    this.helpers.sendRequest('GET', url, {}, this.sendForgotPasswordRequest.bind(this), this.displayServerError.bind(this, 'userIdError', 'userIdInput'));
  }

  changeSubmitButtonState(text, action) {
    this.elements.forgotDialogSubmit.innerHTML = text;
    this.elements.forgotDialogSubmit.classList[action]('loading');
  }

  sendForgotPasswordRequest(data) {
    const { role } = data;
    const isPesa = role === 'PESA';
    const isDriver = role === 'DRIVER';
    const otherRole = role === 'OTHER';
    const userName = this.elements.userIdInput.value.trim();
    const url = isPesa ? `${ FLEET_URL }${ encodeURIComponent(userName) }/resetPassword` : `${ BASE_URL }forgot/password`;
    const body = isPesa ? {} : {
      email: 'true',
      phone: 'false',
      userName
    };

    this.requestInProgress = false;

    if (isDriver) {
      this.displayServerError('userIdError', 'userIdInput', { errorCode: 'DRIVER' });

      return false;
    }

    if (otherRole) {
      this.displayServerError('userIdError', 'userIdInput', { errorCode: 'DR0060' });

      return false;
    }

    this.helpers.sendRequest('POST', url, body, this.displaySuccessConfirmation.bind(this), this.displayServerError.bind(this, 'userIdError', 'userIdInput'));
  }

  displaySuccessConfirmation() {
    this.elements.confirmationDialog.classList.remove('hidden');
    this.elements.forgotDialogForm.classList.add('hidden');
    this.requestInProgress = false;
  }

  onKeyUpEmailHandler() {
    this.elements.emailInput.parentElement.classList.remove('validation-error');
    this.elements.emailError.innerHTML = '';

    this.validateEmail();
  }

  validateEmail() {
    let validationError = null;
    let isEmailValid = true;

    if (!this.helpers.isEmailValid(this.elements.emailInput)) {
      validationError = 'Invalid Email';
    }

    if (this.elements.emailInput.value === '') {
      validationError = 'Required';
    }

    if (validationError !== null) {
      this.elements.emailInput.parentElement.classList.add('validation-error');
      this.elements.emailError.innerHTML = validationError;
      isEmailValid = false;
    }

    return isEmailValid;
  }

  onKeyUpUserIdHandler() {
    this.elements.userIdInput.parentElement.classList.remove('validation-error');
    this.elements.userIdError.innerHTML = '';

    this.validateUserId();
  }

  validateUserId() {
    let isUserIdValid = true;
    let validtaionError = null;

    if (this.elements.userIdInput.value.length === 0) {
      validtaionError = 'Required';
    } else if (this.elements.userIdInput.value.length < 3) {
      validtaionError = 'Invalid User ID';
    }

    if (validtaionError) {
      this.elements.userIdInput.parentElement.classList.add('validation-error');
      this.elements.userIdError.innerHTML = validtaionError;
      isUserIdValid = false;
    }

    return isUserIdValid;
  }

  displayServerError(elementForDisplaying, invalidInput, data) {
    const { errorCode } = data;
    const errorToDisplay = ERROR_MESSAGES[errorCode];

    this.changeSubmitButtonState('Submit', 'remove');
    this.requestInProgress = false;

    if (errorToDisplay) {
      this.elements[elementForDisplaying].innerHTML = errorToDisplay;
      this.elements[elementForDisplaying].parentElement.classList.add('validation-error');
      this.helpers.scrollToErrorField(this.elements[invalidInput]);
    } else {
      this.helpers.displayAlert();
    }
  }

  onTouchStart() {
    this.elements.forgotDialogSubmit.classList.remove('mobile-touch');
  }

  onTouchEnd() {
    this.elements.forgotDialogSubmit.classList.add('mobile-touch');
  }

  triggerCloseModal() {
    EventBus.publish('modal:close');
  }

  backToLogin() {
    this.helpers.scrollToTop();
    this.unbindListeners();
    this.elements.forgotDialog.parentElement.removeChild(this.elements.forgotDialog);
    this.elements.loginInfo.style.display = 'block';

    this.helpers.clearLinks(ELEMENT_IDS, this.elements);
  }

  unbindListeners() {
    this.elements.userId.removeEventListener('change', this.toggleOptionsVisability.bind(this), false);
    this.elements.password.removeEventListener('change', this.toggleOptionsVisability.bind(this), false);
    this.elements.forgotDialogSubmit.removeEventListener('click', this.onSubmitHandler.bind(this), false);
    this.elements.forgotDialogSubmit.removeEventListener('touchstart', this.onTouchStart.bind(this));
    this.elements.forgotDialogSubmit.addEventListener('touchend', this.onTouchEnd.bind(this));
    this.elements.confirmationButton.removeEventListener('click', this.triggerCloseModal.bind(this), false);
    this.elements.forgotDialogBack.removeEventListener('click', this.backToLogin.bind(this), false);
    this.elements.emailInput.removeEventListener('keyup', this.onKeyUpEmailHandler.bind(this), false);
    this.elements.emailInput.removeEventListener('focusout', this.validateEmail.bind(this), false);
    this.elements.userIdInput.removeEventListener('keyup', this.onKeyUpEmailHandler.bind(this), false);
    this.elements.userIdInput.removeEventListener('focusout', this.validateUserId.bind(this), false);

    if (!navigator.userAgent.match(/Trident.*rv:11\./)) {
      this.elements.emailInput.removeEventListener('input', this.onKeyUpEmailHandler.bind(this), false);
      this.elements.userIdInput.removeEventListener('input', this.onKeyUpUserIdHandler.bind(this), false);
    }

    this.modalWasCloseUnsubscribe();
  }

}

export default ForgotDialogController;
