export const AUTHORIZED_USER_ROLES = [
  'DRIVER_ADMIN',
  'PESA'
];

export const SERVER_ERROR_CODE = 'DRM400';

export const ERROR_MESSAGES = {
  [SERVER_ERROR_CODE]: 'Error. Please try again',
  EX0001: 'Incorrect User ID or Password',
  DR0005: 'Incorrect User ID or Password',
  DR0060: 'Incorrect User ID or Password'
};

// eslint-disable-next-line
export const EMAIL_REGEXP = /^[a-zA-Z0-9_\-]+(?:\.[a-zA-Z0-9_\-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z-0-9]*[a-zA-Z])+$/;
export const USER_ID_REGEXP = /^[a-zA-Z0-9_@.\-]+$/;
