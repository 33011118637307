/* eslint-disable no-unused-vars,angular/document-service */
import ModalController from './privacy-policy/modal.controller';
import ForgotDialogController from './forgot-dialog/forgot-dialog.controller';
import LoginFormController from './login-form/login-form.controller';
import MainController from './main/main.controller';
import LoginModalController from './login-modal/login-modal.controller';

document.addEventListener('DOMContentLoaded', () => {
  const loginController = new LoginFormController();
  const modalController = new ModalController();
  const forgotDialogController = new ForgotDialogController();
  const mainController = new MainController();
  const loginModal = new LoginModalController();

  if (document.getElementById('loginInfo')) { loginController.init(); }
  if (document.getElementById('toggleLoginButton')) {
    loginModal.init();
    loginController.displayError();
    mainController.init();
  }
}, false);
