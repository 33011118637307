export const EventBus = {
  channels: {},

  subscribe(eventName, listener) {
    if (!this.channels[eventName]) {
      this.channels[eventName] = [];
    }
    this.channels[eventName].push(listener);

    return () => {
      this.channels[eventName] = this.channels[eventName].filter((sub) => sub !== listener);
    };
  },

  publish(eventName, data) {
    const channel = this.channels[eventName];

    if (!channel || !channel.length) {
      return;
    }

    channel.forEach((listener) => listener(data));
  }
};

